/* Estilo para el encabezado completo del DataGrid */
.header-data-grid .MuiDataGrid-columnHeader {
    background-color: #f39a34;  /* Color verde */
    color: white;
}

/* Estilo para el título del encabezado para asegurarnos de que el texto sigue siendo blanco */
.header-data-grid .MuiDataGrid-columnHeaderTitle {
    color: white;
}

/* Estilo para que el encabezado no cambie de color al pasar el mouse sobre él */
.header-data-grid .MuiDataGrid-columnHeader:hover {
    background-color: #f39a34;  /* El mismo color verde */
}
